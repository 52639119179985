#{$all-text-inputs} {
    position: relative;
    transition: border $fast;
    border: 1px solid var(--border);
    border-radius: var(--input-radius);
    padding: 10px 18px;
    width: 100%;
    height: $input-height;
    color: $dark-grey;
    font-size: 16px;
    box-sizing: border-box;
    appearance: none;

    &[readonly] {
        &:hover {
            box-shadow: none;
        }
    }

    &[disabled] {
        cursor: not-allowed;
        background-color: #f9f9fb;
    }

    &::placeholder {
        color: $grey;
    }

    &:focus {
        outline: 0;
        border-color: $brand;
        box-shadow: none;
    }

    &.small {
        padding: 0 11px;
        height: $input-small-height;
        font-size: 14px;

        @supports (-webkit-touch-callout: none) {
            /* CSS specific to iOS devices */
            font-size: 16px; /* minumim font size wihtout zooming on ios) */
        }
    }

    &.value,
    &.percentage {
        width: 100px;

        &.small {
            width: 80px;
        }
    }

    &.numbered {
        width: 150px;
    }

    &.shadow {
        position: absolute;
        z-index: 1;
        border: 1px solid white;
        color: $grey;
    }

    &.counter {
        padding-right: 50px;
    }

    &.error,
    &.invalid {
        border-color: var(--warning);
    }
}

textarea {
    padding: 10px 18px;
    min-height: 126px;
    line-height: 26px;
    resize: none !important;

    &.max {
        padding: 10px 48px 10px 18px;
    }
}

.input-counter {
    position: relative;

    input {
        padding-right: 60px;
    }

    .max-length {
        position: absolute;
        right: 18px;
        bottom: 16px;
        float: right;
        color: $grey;
        font-size: 14px;
        font-weight: var(--medium);
    }
}

.input-button {
    position: relative;
    button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 17px;
    }
}

select {
    @extend %select-base;
}

.info {
    padding: 12px 0 0;
}

h4 {
    margin: 0 0 14px;
    line-height: 22px;
    font-size: 16px;
}

hr {
    border-top: 1px solid var(--border);
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    position: relative;
    margin: 28px 0;
    // margin: 28px 0 28px -34px;
    // width: calc(100% + 68px);
}

.react-select {
    &.year {
        width: 130px;
    }
}
